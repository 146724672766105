import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import AnimateFormatted from '../components/AnimateFormatted';
import FadeUpOnLoad from '../components/FadeUpOnLoad';
import FullBleedImage from '../components/FullBleedImage';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      content: contentfulContactPage {
        metaTitle
        metaDescription
        email
        telephone
        linkedin
        heroSideTitle
        heroTitle1
        heroParagraph1 {
          heroParagraph1
        }
        heroTitle2
        heroParagraph2 {
          heroParagraph2
        }
        fullBleedImage {
          alt: description
          fluid(maxWidth: 2310, quality: 95) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        footerPromo
        footerPromoTheme
      }
    }
  `);

  const {
    content: {
      metaTitle,
      metaDescription,
      email,
      telephone,
      linkedin,
      heroSideTitle,
      heroTitle1,
      heroParagraph1: { heroParagraph1 },
      heroTitle2,
      heroParagraph2: { heroParagraph2 },
      fullBleedImage,
      footerPromo,
      footerPromoTheme,
    },
  } = data;

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' }, undefined);

  return (
    <Layout
      headerTheme="dark"
      footerPromo={footerPromo}
      footerPromoTheme={footerPromoTheme}
    >
      <SEO
        title={metaTitle}
        description={metaDescription}
        themeColor="rgb(255, 255, 255)"
      />
      <section className="panel panel--white panel--header lead-split-panel lead-split-panel--grey lead-split-panel--contact">
        <div className="lead-split-panel__inner">
          <h1 className="lead-split-panel__title lead-split-panel__title--contact">
            <AnimateFormatted
              on="load"
              text={heroTitle1}
              skip={!isDesktop && 5}
            />
          </h1>
          <FadeUpOnLoad on="load" skip={!isDesktop && 7}>
            <p className="lead-split-panel__text">{heroParagraph1}</p>
          </FadeUpOnLoad>
          <h1 className="lead-split-panel__title  lead-split-panel__title--contact lead-split-panel__title--spaced">
            <AnimateFormatted
              on="load"
              text={heroTitle2}
              skip={!isDesktop && 13}
            />
          </h1>
          <FadeUpOnLoad>
            <p className="lead-split-panel__text">{heroParagraph2}</p>
          </FadeUpOnLoad>
        </div>
        <div className="lead-split-panel__inner">
          <div className="lead-split-panel__contact">
            <FadeUpOnLoad as="h3" extraClass="lead-split-panel__contact-title">
              {heroSideTitle}
            </FadeUpOnLoad>

            <ul className="lead-split-panel__contact-details">
              <FadeUpOnLoad
                as="li"
                extraClass="lead-split-panel__contact-details__item"
                delayBy={!isDesktop && 2}
              >
                E.{' '}
                <a
                  className="lead-split-panel__contact-details__anchor"
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
              </FadeUpOnLoad>
              <FadeUpOnLoad
                as="li"
                extraClass="lead-split-panel__contact-details__item"
                delayBy={!isDesktop && 3}
              >
                T.{' '}
                <a
                  className="lead-split-panel__contact-details__anchor"
                  href={`tel:${telephone}`}
                >
                  {telephone}
                </a>
              </FadeUpOnLoad>
              <FadeUpOnLoad
                as="li"
                extraClass="lead-split-panel__contact-details__item"
                delayBy={!isDesktop && 4}
              >
                <a
                  href={linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="logo logo--linkedin logo--linkedin--grey"
                >
                  LinkedIn
                </a>
              </FadeUpOnLoad>
            </ul>
          </div>
        </div>
      </section>
      <FullBleedImage fluid={fullBleedImage.fluid} alt={fullBleedImage.alt} />
    </Layout>
  );
};

export default Contact;
